<script>
import api from "@/api";

export default {
  name: "TOS",
  data() {
    return {
      space: null,
    };
  },
  methods: {
    getSpace() {
      api.SpaceLocations.slug(this.slug).then((response) => {
        this.space = response.data;
      });
    },
  },
  computed: {
    slug() {
      return this.$route.params.slug;
    },
  },
  created() {
    this.getSpace();
  },
};
</script>

<template>
  <div id="TOS" class="container">
    <h3>Terms of Service</h3>
    <template v-if="space">
      <h4>{{ space.name }}</h4>

      {{ space.tos }}
    </template>
  </div>
</template>

<style lang="scss" scoped>
#TOS {
  margin-top: 10px;
}
</style>
